import { h, Component } from "preact";
import { Router } from "preact-router";

// Code-splitting is automated for routes
import Widget from "../routes/widget";

export default class App extends Component {
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url;
	};

	render() {
		return (
			<div id="app">
				<Router onChange={this.handleRoute}>
					<Widget path="/" />
					<Widget path="/:brand/" />
					<Widget path="/:brand/:tab" />
				</Router>
			</div>
		);
	}
}
